<template>
  <div class="form-step form-step-two">
    <div class="q-gutter-md">
      <div>
        <q-radio
          v-for="(option, i) in partnerTypes"
          :key="i"
          v-model="partnerType"
          :val="option.value"
          :label="option.label"
          color="pink-4"
        />
      </div>

      <template v-for="option in partnerTypes">
        <template v-if="partnerType == option.value">
          <div
            :key="z"
            v-for="(field, z) in getFieldByType(stepFields, option.value)"
          >
            <FormInputComponent
              :stepInput="field"
              @inputChange="(value) => handleInputChange(value, field.index)"
            />
          </div>
        </template>
      </template>

      <template v-if="partnerType == 'pj'">
        <FormCnaesComponent @getCnaes="setCnaes" />
      </template>
    </div>
    
    <ErrorMessageComponent 
      v-if="errorMessage" 
      :errorMessage="errorMessage"
    />

    <FormActionsComponent
      :step="2"
      class="form-actions"
      @return-form="handleReturn"
      @next-step="handleStepData"
      :btnSubmitIsLoading="btnSubmitIsLoading"
    />
  </div>
</template>

<script setup>
import {
  formatData,
  partnerTypes,
  getFormFieldsByStep,
} from "../form-config/index.js";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { onMounted, defineProps, watch, ref, defineEmits, defineAsyncComponent } from "vue";

import FormInputComponent from "../FormInputComponent.vue";
import FormCnaesComponent from "../FormCnaesComponent.vue";
import FormActionsComponent from "../FormActionsComponent.vue";
const ErrorMessageComponent = defineAsyncComponent(() => import('../ErrorMessage.vue'));

const { useActions } = createNamespacedHelpers("auth");

const emit = defineEmits([
  "save-form",
  "return-form",
  "set-selected-partner-type",
]);

const props = defineProps({
  selectedPartnerType: {
    type: String,
    default: "pf",
  },
  refForm: {
    type: Object,
    required: true
  }
});

const { signUpFlipPartnerTypeAction } = useActions([
  "signUpFlipPartnerTypeAction",
]);

const cnaes = ref([]);
const partnerType = ref("pf");
const stepFields = ref(getFormFieldsByStep(2).fields);
const btnSubmitIsLoading = ref(false);
const errorMessage = ref(null);

async function handleStepData() {
  if (partnerType.value === "pj" && cnaes.value.length === 0) {
    errorMessage.value = "Selecione ao menos um CNAE para continuar."
    return;
  }

  try {
    btnSubmitIsLoading.value = true
    errorMessage.value = null;

    const output = await props.refForm.validate();

    if ( !output ) throw new Error("Houve um erro ao avançar para a próxima etapa");

    const formattedData = formatData(stepFields.value);

    emit("save-form", {
      ...formattedData,
      ...(partnerType.value === "pj" ? { pj_cnaes: cnaes.value } : {}),
    });
  } catch (error) {
    console.log(error.message)
  } finally {
    btnSubmitIsLoading.value = false
  }
}

function handleInputChange(newValue, index) {
  stepFields.value[index].value = newValue.value;
}

function getFieldByType(fields, type) {
  return fields.filter((e) => e.group === type);
}

function setCnaes(emittedCnaes) {
  cnaes.value = emittedCnaes;
}

function handleReturn() {
  emit("return-form");
}

watch(partnerType, (value) => {
  signUpFlipPartnerTypeAction(value);
  emit("set-selected-partner-type", value);
});

onMounted(() => {
  partnerType.value = props.selectedPartnerType;
});
</script>
