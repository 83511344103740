<template>
  <template v-for="(cnae, i) in cnaesGroups" :key="i">
    <div class="q-mt-sm text-bold text-pink-4" v-html="cnae.title" />
    <q-option-group
      color="pink-4"
      type="checkbox"
      v-model="cnae.model"
      :options="cnae.options"
      class="option-group"
      v-on:update:model-value="clearOtherCnaes(i)"
    />
  </template>
</template>

<script setup>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { ref, defineEmits, watch } from "vue";
import { useI18n } from "vue-i18n";

const emit = defineEmits(["get-cnaes"]);

const authNamespace = createNamespacedHelpers("auth");
const { t } = useI18n();

const { signup_cnaes } = authNamespace.useGetters({
  signup_cnaes: "getSignUpCnaes",
});

const cnaesGroups = ref(formatCnaes(signup_cnaes.value));

function formatCnaes(cnaes) {
  return Object.values(cnaes).map((cnae, i) => {
    return {
      title: t("signup.cnaes." + i),
      type: t("signup.cnaes.type." + i),
      options: cnae.map((e) => ({
        label: e.description,
        value: e.external_id,
      })),
      model: [],
    };
  });
}

function clearOtherCnaes(index) {
  cnaesGroups.value.forEach((cnae, i) => {
    if (i !== index) cnae.model = [];
  });
}

watch(cnaesGroups.value, (value) => {
  const cnaes = value.map((cnae) => cnae.model).flat();
  emit("get-cnaes", cnaes);
});
</script>

<style lang="scss" scoped>
.option-group {
  font-size: 8pt !important;
  margin-top: -1px;
}
</style>
