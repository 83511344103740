import { isValidFullName } from "../../../../../../shared/helpers/isValid/isValidFullName";
import {
  validateCelular,
  validateCPF,
  validateEmail,
  validateSlug,
  validateCNPJ,
} from "./services";
import i18n from "@/i18n/wrappers/i18n";

const t = i18n.global.t;

// Função de validação genérica
async function validateField(validator, value, slug = "flipnet") {
  let success = true;
  let failMessage = "";
  await validator(value, slug)
    .then(() => (success = true))
    .catch(({ data, errors }) => {
      success = false;
      // Assume que a chave do erro é o nome da função sem 'validate'
      const errorKey = validator.name.replace("validate", "").toLowerCase();

      failMessage = errorKey == "slug" ? data.message : errors[errorKey][0];
    });

  return success || failMessage;
}

export function fullNameRule(value) {
  if (!value) {
    return t("signup.fields.complete_name");
  }

  if (!isValidFullName(value)) {
    return t("signup.fields.invalid_fullname");
  }

  // Retorno padrão caso o nome seja válido
  return true;
}

export function emailRule(value, slug) {
  return validateField(validateEmail, value, slug);
}

export function celularRule(value, slug) {
  return validateField(validateCelular, value, slug);
}

export function cpfRule(value, slug) {
  return validateField(validateCPF, value, slug);
}

export function cnpjRule(value, slug) {
  return validateField(validateCNPJ, value, slug);
}

export function slugRule(value, slug) {
  return validateField(validateSlug, value, slug);
}


/**
 * Valida se o valor de entrada contém apenas caracteres alfanuméricos.
 * 
 * Esta função verifica se o valor fornecido consiste apenas em letras (maiúsculas e minúsculas) e números.
 * 
 * @param {string} value - O valor a ser validado.
 * @returns {boolean|string} - Retorna `true` se o valor for alfanumérico, uma mensagem de erro localizada caso contrário, ou `false` se o valor for inválido.
*/
export function validateAlphaNumeric(value) {

  if ( !value ) return false;

  // Regex que permite apenas letras de a-z (maiúsculas e minúsculas) e números de 0-9
  const regex = /^[a-zA-Z0-9]*$/;

  if (!regex.test(value)) {
    return t("signup.fields.slug_alpha_numeric");
  }

  return true;
}


export function dateRule(value) {
  const minAge = 18;
  const maxAge = 100;
  const birthDate = new Date(value);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return (
    (age >= minAge && age <= maxAge) || t("signup.fields.invalid_birth_date")
  );
}

export function requiredRule(value, field) {
  return (value && value.length > 0) || t("signup.required", { field });
}
