<template>
  <div class="form-step-success">
    <div class="q-gutter-md">
      <p
        class="text-center"
        v-html="$t('signup.success_page.email', { email: userEmail })"
      />
      <p class="text-center">
        Caso o token expire, solicite um novo token no seu e-mail para o
        <span style="color: #00b0ff">suporte@flip.net.br</span>
      </p>
      <hr />
      <div class="text-center">
        <p
          class="text-first-access"
          v-html="$t('signup.success_page.first_access')"
        ></p>
      </div>
    </div>
  </div>
  <div>
    <q-btn
      flat
      @click="handleGoNext"
      icon-right="arrow_forward"
      :label="$t('signup.success_page.action')"
      class="bg-pink-4 text-white full-width default-rounded"
    />
  </div>
</template>

<script setup>
import { baseFrontUrl } from "@/shared/http/api";
import { defineProps } from "vue";

defineProps({
  userEmail: {
    type: String,
    default: "user@flip.net.br",
  },
});

function handleGoNext() {
  window.open(`${baseFrontUrl}/flipnet/login`, "_blank");
}
</script>
