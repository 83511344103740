<template>
  <div class="row">
    <div class="col-6" v-if="getStepActions(step).actions.length > 1">
      <q-btn
        flat
        label="Voltar"
        @click="handleReturn"
        icon="arrow_back"
        class="full-width default-rounded"
      />
    </div>
    <div
      :class="{
        'col-12': getStepActions(step).actions.length === 1,
        'col-6': getStepActions(step).actions.length > 1,
      }"
    >
      <q-btn
        flat
        type="submit"
        label="Próximo"
        @click="handleGoNext"
        :loading="btnSubmitIsLoading"
        icon-right="arrow_forward"
        class="bg-pink-4 text-white full-width default-rounded next"
      />
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from "vue";
import { getStepActions } from "./form-config/index.js";

const emit = defineEmits(["next-step", "return-form"]);

defineProps({
  step: {
    type: Number,
    required: true,
    default: 1,
  },
  btnSubmitIsLoading: {
    type: Boolean,
    required: true
  }
});

function handleReturn() {
  emit("return-form");
}

function handleGoNext(e) {
  e.preventDefault()

  emit("next-step");
}
</script>
