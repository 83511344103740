<template>
  <p class="input-label">{{ input.label }}</p>
  <q-input
    dense
    filled
    ref="inputRef"
    lazy-rules="ondemand"
    v-model="input.value"
    :rules="input.rules"
    :mask="input.mask"
    :placeholder="input.placeholder"
    :type="input.type"
  />
  <p class="input-caption" v-if="input.caption">{{ input.caption }}</p>
</template>

<script setup>
import { defineProps, ref, watch, defineEmits } from "vue";
import { isValidFullName } from "../../../../../shared/helpers/isValid/isValidFullName";

const emit = defineEmits(["input-change"]);

const props = defineProps({
  stepInput: {
    type: Object,
    required: true,
  },
});

const input = ref({ ...props.stepInput });
const inputRef = ref(null)

// Watch para monitorar mudanças no input e validar
watch(input.value, (newValue) => {
 const inputName = input.value.name;

 inputValidation(inputName, newValue.value)

 emit("input-change", newValue)
});


// Função de validação do input
function inputValidation(inputName, value) {

  if ( inputName === "nome" && isValidFullName(value) ) {
    inputRef.value.resetValidation()
  } else if ( inputName !== "nome" ) {
    inputRef.value.resetValidation()
  }

}
</script>
