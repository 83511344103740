<template>
  <q-card-section class="text-center">
    <div>
      <div class="q-mb-md">
        <span
          style="font-size: 1.5rem"
          v-html="
            step <= 3
              ? $t('signup.top_messages.steps')
              : $t('signup.top_messages.success')
          "
        />
      </div>
      <img
        :src="getStepImage"
        alt="Flipnet Signup Steper"
        :class="{
          'img-success': step > 3,
          'full-width': step <= 3,
        }"
      />
    </div>
    <hr v-if="step <= 3" />
  </q-card-section>
</template>

<script setup>
import { computed, defineProps } from "vue";
import step1 from "@/assets/steps/1.png";
import step2 from "@/assets/steps/2.png";
import step3 from "@/assets/steps/3.png";
import success from "@/assets/steps/success.png";

const props = defineProps({
  step: {
    type: Number,
    default: 1,
  },
});

const getStepImage = computed(() => {
  switch (props.step) {
    case 1:
      return step1;
    case 2:
      return step2;
    case 3:
      return step3;
    default:
      return success;
  }
});
</script>

<style lang="scss" scoped>
$pink: #f16192;

.color-pink {
  color: $pink;
}

.full-width {
  width: 100%;
}

.img-success {
  width: 71px;
}
</style>
