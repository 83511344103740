<template>
  <div class="form-step form-step-one">
    <div class="q-gutter-md">
      <div v-for="(field, index) in stepFields" :key="index">
        <FormInputComponent
          :stepInput="field"
          @inputChange="(value) => handleInputChange(value, index)"
        />
      </div>
      <div>
        <q-checkbox
          v-model="optIn"
          :label="$t('signup.opt_in')"
          color="pink-4"
        />
        <q-checkbox
          v-model="terms"
          :label="$t('signup.use_terms')"
          color="pink-4"
          required
        />
        <p
          style="font-size: 8pt"
          class="text-justify q-mt-sm"
          v-html="$t('signup.help_message')"
        />
      </div>
    </div>

    <ErrorMessageComponent v-if="errorMessage" :errorMessage="errorMessage" />

    <FormActionsComponent
      class="form-actions"
      :step="1"
      @next-step="handleStepData"
      :btnSubmitIsLoading="btnSubmitIsLoading"
    />
  </div>
</template>

<script setup>
import { getFormFieldsByStep, formatData } from "../form-config/index.js";
import { ref, defineEmits, defineProps, defineAsyncComponent } from "vue";
import { uuid } from "uuidv4";

import FormInputComponent from "../FormInputComponent.vue";
import FormActionsComponent from "../FormActionsComponent.vue";
const ErrorMessageComponent = defineAsyncComponent(() =>
  import("../ErrorMessage.vue")
);

const emit = defineEmits(["save-form"]);

const props = defineProps({
  refForm: {
    type: Object,
    required: true,
  },
});

const stepFields = ref(getFormFieldsByStep(1).fields);
const optIn = ref(true);
const terms = ref(true);
const errorMessage = ref(null);
const btnSubmitIsLoading = ref(false);

async function handleStepData() {
  if (!terms.value) {
    errorMessage.value = "Concorde com os termos de uso para continuar";
    return;
  }

  try {
    btnSubmitIsLoading.value = true;

    errorMessage.value = null;

    const output = await props.refForm.validate();

    if (!output)
      throw new Error("Houve um erro ao avançar para a próxima etapa");

    const formattedData = formatData(stepFields.value);

    const data = {
      ...formattedData,
      slug_parent: "flipnet",
      opt_in: optIn.value,
      password: uuid(),
    };

    emit("save-form", data);
  } catch (error) {
    console.log(error.message);
  } finally {
    btnSubmitIsLoading.value = false;
  }
}

function handleInputChange(newValue, index) {
  stepFields.value[index].value = newValue.value;
}
</script>
