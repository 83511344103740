<template>
  <div class="form-step form-step-three">
    <div class="q-gutter-md">
      <div :key="index" v-for="(field, index) in stepFields">
        <FormInputComponent
          :stepInput="field"
          @inputChange="(value) => handleInputChange(value, index)"
        />
      </div>
    </div>
    <ErrorMessageComponent
      v-if="errorMessage"
      :errorMessage="errorMessage"
    />
    <FormActionsComponent
      :step="3"
      class="form-actions"
      @return-form="handleReturn"
      @next-step="handleStepData"
      :btnSubmitIsLoading="btnSubmitIsLoading"
    />
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, watch, defineAsyncComponent } from "vue";
import { formatData, getFormFieldsByStep } from "../form-config/index.js";
import FormActionsComponent from "../FormActionsComponent.vue";
import FormInputComponent from "../FormInputComponent.vue";

const ErrorMessageComponent = defineAsyncComponent(() => import('../ErrorMessage.vue'));

const emit = defineEmits(["save-form", "return-form"]);

const props = defineProps({
  refForm: {
    type: Object,
    required: true
  },
  buttonSubmitIsLoading: {
    type: Boolean,
    required: true
  },
  errorMessageWhenSubmittingForm: {
    type: String,
    required: true
  }
})

const stepFields = ref(getFormFieldsByStep(3).fields);
const btnSubmitIsLoading = ref(false);
const errorMessage = ref(null);


watch(() => props.errorMessageWhenSubmittingForm, (newValue) => {
  errorMessage.value = newValue
})

watch(() => props.buttonSubmitIsLoading, (newValue) => {
  btnSubmitIsLoading.value = newValue
})

async function handleStepData() {
  
  btnSubmitIsLoading.value = true

  try {
    const output = await props.refForm.validate();

    if ( !output ) throw new Error("Houve um erro ao avançar para a próxima etapa");

    const formattedData = formatData(stepFields.value);
    emit("save-form", formattedData);

  } catch (error) {
    console.log(error.message)
  } finally {
    btnSubmitIsLoading.value = false
  }
}

function handleInputChange(newValue, index) {
  stepFields.value[index].value = newValue.value;
}

function handleReturn() {
  errorMessage.value = null;

  emit("return-form");
}
</script>