import services from "@/shared/http";
import store from "@/store";

/**
 * SERVICES
 */
export const validateEmail = (value, parentSlug) => {
  return services.validations.email(null, {
    partnerSlug: parentSlug
      ? parentSlug
      : store.getters["partner/getPartnerSlug"],
    email: value,
  });
};

export const validateCelular = (value, parentSlug) => {
  return services.validations.cel(null, {
    partnerSlug: parentSlug
      ? parentSlug
      : store.getters["partner/getPartnerSlug"],
    phone: value,
  });
};

export const validateCPF = (value, parentSlug) => {
  return services.validations.cpf(null, {
    partnerSlug: parentSlug
      ? parentSlug
      : store.getters["partner/getPartnerSlug"],
    cpf: value.replace(/[^0-9]/g, ""),
  });
};

export const validateCNPJ = (value, parentSlug) => {
  return services.validations.cnpj(null, {
    partnerSlug: parentSlug
      ? parentSlug
      : store.getters["partner/getPartnerSlug"],
    cnpj: value.replace(/[^0-9]/g, ""),
  });
};

export const validateSlug = (value, parentSlug) => {
  return services.validations.slug(null, {
    partnerSlug: parentSlug
      ? parentSlug
      : store.getters["partner/getPartnerSlug"],
    newSlug: value,
  });
};
